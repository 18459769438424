/* You can add global styles to this file, and also import other style files */
@import './sass/variables';
@import './sass/awesome/fa-light';
@import './sass/awesome/fontawesome';
@import "../node_modules/bootstrap/scss/bootstrap";

@font-face {
  font-family: 'nunito';
  src: url('./assets/fonts/nunito/nunito-regular-webfont.woff2') format('woff2'),
  url('./assets/fonts/nunito/nunito-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'nunito';
  src: url('./assets/fonts/nunito/nunito-bold-webfont.woff2') format('woff2'),
  url('./assets/fonts/nunito/nunito-bold-webfont.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

* {
  font-family: $font;
}

html {
  height: 100%;
  font-size: 16px;
}

body {
  background-color: transparent !important;
  min-height: 100%
}

input:invalid {
  box-shadow: none;
}

.tooltip {
  opacity: 1;
}