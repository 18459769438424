/* FONTS */
$font: 'nunito';
$text-gray-01: #454648;
$text-gray-02: #808285;
$text-gray-03: #D1D3D4;
$text-gray-04: #3a3b3b;

/* GENERAL COLORS */
$white: #FFFFFF;
$black: #333;
$primary-gray-hover: #363636;
$primary-yellow: #FFD200;
$primary-yellow-hover: #FFB500;
$primary-red: #FA5E5B;
$primary-red-hover: #D14D4A;
$primary-green: #16C98D;
$secondary: #00448D;
$secondary-focus: #E6E7E8;
$primary-purple: #9013fe;
$primary-blue: #00448c;
$primary-blue-hover:#003164;
$color-2D2A26: #2D2A26;
$color-00CCEA: #00CCEA;
$color-FDDA24: #FDDA24;
$color-454648: #454648;
$color-FFCF01: #ffcf01;
$color-808285: #808285;
$color-a2a2a3 : #a2a2a3;
$color-2C2B29 : #2C2B29;
$color-00000012 : #00000012;
$color-BFBFBF : #BFBFBF;
$color-FF7F37: #FF7F37;
$color-00C389: #00C389;



/* BACKGROUND COLORS */
$bg-F7F7F7: #F7F7F7;
$bg-00448c: #00448c;
$bg-disabled: #ddd;
$background-gray-01: #58595B;
$background-gray-02: #6D6E71;
$background-gray-03: #D1D3D4;
$background-gray-04: #E6E7E8;
$background-gray-05: #F7F7F7;
$background-gray-06: #979797;
$background-gray-07: #DDD;
$background-gray-08:  #FFFFFF;
$background-yellow-01: #FFCF01;
$background-gray-scroll: #e2e2e2;

/* BORDERS */
$border-gray: #F1F1F1;
$border-gray-02: #979797;
$border-gray-03: #E9E9E9;
$border-gray-04: #DADBDC;
$border-gray-05: #E4E4E4;
$border-gray-06: #3a3b3b;
$border-gray-07: #E0E0E0;

/* SHADOWS */
$shadow-black-07: rgba(0, 0, 0, 0.07);